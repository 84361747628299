.refill-row-white .refill-row-white {
  padding-top: 4px;
  padding-bottom: 4px;
}

.refill-row-grey {
  background: #ccc;
}
.version-name {
  text-align: right;
  padding: 20px;
}
