.keyboard_show {
  flex: 1;
  height: 270px;
  max-width: 600px;
  font-size: 2.5em;
  margin: 10px auto;
  justify-content: flex-end;
  align-items: baseline;
  align-content: flex-end;
  transition: 0.5s;
  padding-top: 40px;
  font-weight: 800;
  color: #333;
}

.keyboard_bottom {
  // width: 100%;
  // max-width: 100%;
  // bottom: 0px;
  // left: 0;
  position: absolute;
  width: 100%;
  display: flex;
  max-width: 100%;
  bottom: 0px;
  left: 0;
  /* top: 0%; */
  justify-content: flex-end;
  align-content: flex-end;
  align-items: flex-end;
}

.keyboard_hide {
  flex: 1;
  height: 0%;
  transition: 0.5s;
  display: none;
}

.close_keyboard_button {
  width: 100px;
  padding: 10px 20px;
  background-color: red;
}
.keyboard-container-style {
  justify-content: space-around;
}
.keyboard-input-style {
  font-size: 26px;
  border-radius: 5px;
  border-width: 0;
  margin: 0 5px;
  border: 1px solid #555;
  padding-right: 20px;
}

.keyboard-input-style:focus {
  box-shadow: 0 0 5px rgba(81, 203, 238, 1);
  padding: 3px 0px 3px 3px;
  margin: 5px 1px 3px 0px;
  border: 1px solid rgba(81, 203, 238, 1);
}
