.payment-options {
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 5px;
  flex-direction: column;
}

.payment-options .btn {
  flex: 1;
  width: 100px !important;
  height: 110px !important;
  padding: 5px;
}

.get-payment-data {
  flex: 3;
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  align-self: center;
  justify-content: center;
  align-content: center;
}

.modal-body {
  text-align: center;
}

.sodexoQR {
  height: 200px;
  width: auto;
}
