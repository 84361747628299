.refilling-items {
  background: white;
  overflow-y: scroll;
  max-height: 520px;
}

.content {
  text-align: center;
}

.item-list {
  width: 100%;
  overflow: scroll;
}

.item-row {
  // width: 1700px;
  // overflow-y: scroll;
  display: flex;
  height: 300px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.item-box {
  margin: 0px 10px 15px !important;
  margin-bottom: 10px;
  border-radius: 10px;
  box-shadow: -3px 1px 4px 0 #bbb;
  background: white;
  text-align: left;
  display: flex;
  height: 260px;
  width: 270px;
  padding: 0 10px;
  flex-direction: column;
  .item-pos {
    display: flex;
    justify-content: center;
    color: #fff;
    font-weight: 700;
    font-size: 20px;
    div {
      width: 60px;
      text-align: center;
    }
  }
  .bg-red {
    background-color: $color-red;
  }
  .bg-green {
    background-color: $color-green;
  }

  .div-2 {
    div {
      width: 50%;
    }
  }

  .justify-center {
    justify-content: center;
  }

  .refill-product-name {
    font-weight: 800;
    display: inline-flex;
    align-items: center;
    white-space: break-spaces;
    min-height: 70px;
  }
  .refill-name {
    align-items: center;
    display: flex;
    width: 100%;
    .change-product {
      text-align: right;
    }
  }
  .refill-price {
    align-items: center;
    display: flex;
    width: 100%;

    .change-price {
      text-align: right;
    }
    .price {
      font-weight: 800;
      display: inline-flex;
      align-items: center;
      min-height: 50px;
      .cancel {
        text-decoration: line-through;
        color: $color-red;
      }
    }
  }
  .quantity {
    display: flex;
    width: 100%;
    text-align: center;
    div {
      width: 33%;
    }
    .quantity-left {
      font-weight: 800;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    .minus {
      text-align: left;
    }
    .plus {
      text-align: right;
    }
  }
}

.row-flex {
  display: flex;
}

.refill-position {
  float: left;
  width: 60%;
  word-wrap: break-word;
  font-weight: 800;
  align-items: center;
  display: flex;
}

.ui.active.visible.fluid.search.selection.dropdown {
  min-height: 260px;
}
input.search {
  border: 1px solid $color-green;
  border-radius: 3px;
  padding: 5px 22px;
}

.refill-qty-button {
  margin: 5px 0px;
}
