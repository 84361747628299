.form-input-field {
  background-color: #ffffffe8;
  width: 80%;
}
.active-input-field {
  animation-name: input-feild-scale;
  animation-duration: 1s;
  transition: 1s;
  animation-fill-mode: forwards;
  border: 3px solid #d80000;
}

@keyframes input-feild-scale {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.01);
    background-color: lightyellow;
  }
}
