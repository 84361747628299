.tick-container {
  display: flex;
  transform: translate(0, -20px);
  align-items: center;
  width: 100%;
  height: 20px;
  color: green;
  z-index: 9999;
  justify-content: center;
}
.tick-in-shrink {
  display: hidden;
}
.tick-container ion-icon {
  font-size: 90px;
  position: relative;
  animation: shrink 1s 1;
}
.tick-container ion-icon::after {
  background-color: #fff;
  position: absolute;
  width: 76px;
  content: '';
  top: 10px;
  left: 7px;
  z-index: -1;
  height: 90px;
  border-radius: 45px;
}
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
}
.custom-button {
  margin: 10px 0;
  border-radius: 10px;
  padding: 20px 30px;
}
.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

.pick-up-button {
  background-color: #b80e0e;
  margin: auto;
  padding: 5px 10px;
  color: #fff;
  width: 80%;
  animation: stretch 1.8s infinite;
}

.cart-image {
  justify-content: center;
  display: inline-flex;
}

.cart-price {
  position: absolute;
  margin-top: -35px;
  background: white;
  border-radius: 5px;
  padding: 3px 7px;
  margin-left: -104px;
  font-weight: bold;
}

@keyframes stretch {
  25% {
    transform: scale(1.2, 0.8);
  }
  35% {
    transform: scale(0.8, 1.2);
  }
  50% {
    transform: scale(1.1, 0.9);
  }
  65% {
    transform: scale(0.9, 1.02);
  }
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}
@keyframes shrink {
  0% {
    transform: scale(2, 2);
    opacity: 0;
  }
  90% {
    opacity: 1;
    transform: scale(1, 1);
  }
  95% {
    opacity: 1;
    transform: scale(1.2, 1.2);
  }

  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
