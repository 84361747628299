.wifi-connecting-container {
  height: 400px;
}

.wifi-circle {
  background-color: #be1717;
  height: 40px;
  width: 40px;
  position: absolute;
  top: 280px;
  left: 45%;
  border-radius: 50%;
  margin: auto;
  animation: glow1 5s infinite;
}

@keyframes glow1 {
  19% {
    background-color: #d5d5d5;
  }
  20% {
    background-color: #f80882;
  }
  100% {
    background-color: #d94031;
  }
}

.wifi {
  height: 150px;
  width: 150px;
  border: 40px solid transparent;
  border-top: 40px solid #d5d5d5;
  border-radius: 50%;
  position: absolute;
  top: -65px;
  left: -54px;
  animation: glow2 5s infinite;
}

@keyframes glow2 {
  39% {
    border-top-color: #d5d5d5;
  }
  40% {
    border-top-color: #f80882;
  }
  100% {
    border-top-color: #d94031;
  }
}
.wifi:before {
  position: absolute;
  content: '';
  height: 320px;
  width: 320px;
  border: 40px solid transparent;
  border-top: 40px solid #d5d5d5;
  border-radius: 50%;
  right: -130px;
  top: -120px;
  animation: glow3 5s infinite;
}

@keyframes glow3 {
  59% {
    border-top-color: #d5d5d5;
  }
  60% {
    border-top-color: #f80882;
  }
  100% {
    border-top-color: #d94031;
  }
}

.wifi:after {
  position: absolute;
  content: '';
  height: 500px;
  width: 500px;
  border: 40px solid transparent;
  border-top: 40px solid #d5d5d5;
  border-radius: 50%;
  right: -225px;
  top: -200px;
  animation: glow4 5s infinite;
}

@keyframes glow4 {
  79% {
    border-top-color: #d5d5d5;
  }
  80% {
    border-top-color: #f80882;
  }
  100% {
    border-top-color: #d94031;
  }
}
