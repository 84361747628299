@keyframes loader-bar {
  0% {
    left: -60%;
    width: 60%;
  }
  100% {
    left: 100%;
    width: 20%;
  }
}

.loader-bar {
  width: 100%;
  height: 3px;
  overflow-x: hidden;
  background-color: $light-bg;

  .bar {
    position: absolute;
    width: 60%;
    height: 3px;
    background-color: $primary;
    border-radius: 0.1875rem;
    animation: loader-bar 1.5s cubic-bezier(0.25, 0.1, 0.25, 1) infinite;
  }
}

.loader-rings {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;

  &.full {
    position: relative;
    top: calc(50% - 32px);
    left: calc(50% - 32px);
    margin: 40px 0px;
  }

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 51px;
    height: 51px;
    margin: 6px;
    border: 6px solid #fff;
    border-radius: 50%;
    animation: loader-rings 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $primary transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}
@keyframes loader-rings {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #02842a;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px #02842a;
  animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 148;
  stroke-dashoffset: 148;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  60% {
    transform: scale3d(1.4, 1.4, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 80px #02842a;
  }
}
