.login-page {
  text-align: center;
  width: 100%;
  height: 100vh;
  position: relative;
}

.login_page_inputs {
  margin: 10px auto;
  font-size: 40px;
}

.login_page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.login-buttton-container {
  margin: 10px auto;
}

.logo_icon {
  flex: 1;
  margin-top: 40px;
  padding: 20px;
}

.logo_image img {
  height: 60px;
  width: auto;
}

.login_form {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.service_mode_icon {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 20px;
  font-size: 30px;
}

.login-page-alert {
  width: 100%;
  color: $color-red;
  padding: 15px 0px;
  margin: 10px 0px;
  font-size: 22px;
}
