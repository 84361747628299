* {
  outline: none;
  box-sizing: border-box;
  margin-block-start: 0;
  margin-block-end: 0;
}

span.bold {
  font-weight: 800;
}

.start-loading-text {
  font-weight: 800;
  color: gray;
  text-align: center;
  margin-top: 50px;
}

.pull-right {
  float: right;
}

button {
  background: none;
  border: none;
  color: $color-red;
  font-weight: bold;
}

body {
  margin: 0;
  overflow-x: hidden;
}

// .container {
//   padding: 0;
// }

// .row {
//   margin-left: 0;
//   margin-right: 0;
// }

.fluid {
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0;
  font-size: 24px;
  text-align: center;
  padding: 0.5rem 1.25rem;
}

.menu {
  max-height: 200px;
  overflow-y: scroll;
  background: #eee;
}

.menu::-webkit-scrollbar {
  display: initial;
}

.menu::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 5px;
  background: #02842a;
  -webkit-transition: color 0.2s ease;
  transition: color 0.2s ease;
}

.menu::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.item {
  padding: 14px;
  border-bottom: 1px solid #80808061;
  cursor: pointer;
}

.item:hover {
  background: #ccc;
  color: black;
}

.selected.item {
  background: $color-grey;
  color: white;
}
