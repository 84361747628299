.shake-component {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;

  /* When the animation is finished, start again */
  animation-iteration-count: 2;
}

.appear-component {
  /*0 Start the shake animation and make the animation last for 0.5 seconds */
  animation: appear 1s;

  /* When the animation is finished, start again */
  animation-iteration-count: 1;
}

.cart-full-popover {
  z-index: 0;
  width: 800px !important;
}

.pop-up-box {
  background-color: #ccc;
  padding: 5px;
  color: #444;
  font-weight: bold;
  border: 1px solid #ccc;
  overflow: hidden;
  border-radius: 5px;
}

.remove-background {
  background-color: transparent !important;
}
.font-black {
  color: #000;
}
.btn-secondary:hover {
  border-color: transparent;
}
@keyframes appear {
  0% {
    transform: translateY(100%);
    height: 0;
    opacity: 0;
    z-index: -1;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
    z-index: 9999;
    height: 100%;
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
