.slides {
  position: relative;
}

/* .slick-prev, .slick-next {
  position: absolute;
  background-color: #eee;
  color:#000;
  top: 50%;
}*/

.small-machine-image-height {
  height: 450px;
}
