.green-background {
  background: #18ce0f !important;
}

.inherit-background {
  background-position-x: unset !important;
  background-position-y: unset !important;
}

.cartDataList {
  position: absolute;
  bottom: 10px;
  width: 94%;
}
