.keyboard-container {
  display: flex;
  flex: 1;
  width: 90%;
  padding: 1px 1px 2px 1px;
  flex-direction: column;
}

.keyboard-container textarea {
  border-width: 0;
  text-align: center;
  flex: 1;
  color: #000;
  font-size: 30px;
}

.numeric-keyboard-container textarea {
  border-width: 0;
  text-align: center;
  flex: 1;
  color: #000;
  font-size: 30px;
  height: 60px;
  max-height: 60px;
  width: 12ch;
  border: none;
  overflow: auto;
  resize: none;
}

.numeric-keyboard-container {
  margin: 10px;
  padding: 10px;
}

.keyboard-input-submit-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.keyboard-container textarea::placeholder {
  color: #ddd;
}

.Keyboard-keys {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border-width: 0;
}

/* .Keyboard-keys button {
  height: 70px;
  align-items: center;
  width: 70px;
  border-radius: 35px;
  margin: 5px;
  font-size: 24px;
  background-color: #e9e9e9;
  color: black;
  border-width: 0px;
} */

.keyboard-output {
  flex: 1;
  padding: 5px;
}

/*------New keyboard textarea -----------*/
.keyboard-new {
  border-radius: 8px !important;
  border: 1px solid #c4cdd5 !important;
  width: 245px !important;
}

/*------New keyboard textarea-----------*/

.keyboard-key {
  color: #000;
}

.key-large button {
  width: 60%;
  height: 50px;
  margin-top: 20px;
  background-color: green;
}

.keyboard-text {
  /* color: #000; */
  color: #454f5b;
  font-size: 22px;
  font-weight: bold;
}

.Old-Keyboard-keys button {
  height: 70px;
  align-items: center;
  width: 70px;
  border-radius: 35px;
  margin: 5px;
  font-size: 24px;
  background-color: #e9e9e9;
  color: black;
  border-width: 0px;
}

/*--------New Buttons-------*/

.Keyboard-keys button {
  width: 70px;
  height: 70px;
  color: #888;
  border: 2px solid var(--colorShadeA);
  border-radius: 100%;
  /* background: var(--colorShadeE); */
  background: #dfe3e8;
  transform-style: preserve-3d;
  transition: all 125ms cubic-bezier(0, 0, 1, 1);
  margin: 10px;
}

.Keyboard-keys button::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--colorShadeC);
  border-radius: inherit;
  box-shadow: 0 0 0 2px var(--colorShadeB), 0 0.35em 0 0 var(--colorShadeA);
  transform: translate3d(0, 0.75em, -1em);
  transition: all 125ms cubic-bezier(0, 0, 1, 1);
}

.Keyboard-keys button:hover::before {
  transform: translate3d(0, 0.75em, -1em);
}

.Keyboard-keys button:active {
  transform: translate(0em, 0.75em);
}

.Keyboard-keys button:active::before {
  transform: translate3d(0, 0, -1em);

  box-shadow: 0 0 0 2px var(--colorShadeB), 0 0.25em 0 0 var(--colorShadeB);
}

.Keyboard-keys button:active {
  background-color: #fff !important;
  color: black !important;
}

.Keyboard-keys button:active::before {
  background: var(--colorShadeD) !important;
}

.touch-animation-keyboard {
  transform: translate(0em, 0.75em) !important;
  background-color: white !important;
  color: #333;
}

.touch-animation-keyboard::before {
  transform: translate3d(0, 0, -1em) !important;
  background: var(--colorShadeD) !important;
  box-shadow: 0 0 0 2px var(--colorShadeB), 0 0.25em 0 0 var(--colorShadeB);
}

/*--------New Buttons css end here-------*/

.keyboard-backspace {
  max-width: 50%;
}

.sms-feedback-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 400px;
  padding: 20px;
}

.sms-feedback-container p {
  text-align: center;
  letter-spacing: 1px;
  flex: 2;
  justify-content: center;
  align-items: center;
  color: #000;
}

.feedback-button-group {
  flex: 1;
  justify-content: center;
  align-items: center;
}

.feedback-button-group button {
  background-image: linear-gradient(45deg, #9cf839, #468404);
  width: 100px;
  height: 40px;
  border-width: 0px;
  border-radius: 20px;
}

.feedback-button-group p {
  font-size: 12px;
  color: blue;
}
